import './index.css'

import { Event, fetchDatoData } from './utils/datocms'
import { useEffect, useRef, useState } from 'react'

import About from './components/About'
import Affiliates from './components/Affiliates'
import ConnectingCultures from './components/ConnectingCultures'
import Contact from './components/Contact'
import Donate from './components/Donate'
import EventBanner from './components/EventBanner'
import Footer from './components/Footer'
import Links from './components/Links'
import Logo from './components/Logo'
// import ProductBanner from './components/productBanner'
import Team from './components/Team'

function App() {
  const [events, setEvents] = useState<Event[]>([])
  const dataFetchAttempted = useRef(false)

  useEffect(() => {
    const loadEvents = async () => {
      try {
        console.log('Fetching DatoCMS data...')
        dataFetchAttempted.current = true
        const result = await fetchDatoData()
        
        if (result && result.allEvents) {
          console.log(`Successfully loaded ${result.allEvents.length} events`)
          setEvents(result.allEvents)
        } else if (result) {
          console.warn('No events found in DatoCMS response')
        } else {
          console.error('Error connecting to DatoCMS')
        }
      } catch (error) {
        console.error('Error in App component while loading events:', error)
      }
    }
    loadEvents()
  }, [])

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-zv-blue-dark via-white to-zv-blue-light">
      {/* Background pattern overlay */}
      <div className="absolute inset-0 bg-grid-slate-100 opacity-30 [mask-image:linear-gradient(0deg,transparent,black,transparent)] pointer-events-none" />
      
      {/* Main content wrapper */}
      <main className="flex-grow flex flex-col items-center w-full relative">
        {/* Content sections */}
        <div className="w-full max-w-xl px-4 pt-8 pb-8">
          <Logo />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <About />
        </div>
        
        {/* Dynamically render EventBanners */}
        {events.map((event) => (
          <div key={event.id} className="w-full max-w-xl px-4 mb-8">
            <EventBanner event={event} />
          </div>
        ))}
        
        {/* <div className="w-full max-w-xl px-4 mb-8">
          <ProductBanner />
        </div> */}
        <div className="w-full max-w-xl px-4 mb-8">
          <Links />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <ConnectingCultures />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <Affiliates />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <Team />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <Donate />
        </div>
        <div className="w-full max-w-xl px-4 mb-8">
          <Contact />
        </div>
      </main>

      {/* Footer */}
      <div className="w-full mt-auto">
        <div className="max-w-xl mx-auto px-4 py-8">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default App
