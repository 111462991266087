import { useState } from 'react'
import { ChevronDown, Heart } from 'lucide-react'

const Donate = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between"
      >
        <div className="flex items-center gap-3">
          <Heart className="w-6 h-6 text-zv-blue-dark" />
          <span className="text-lg font-semibold text-gray-800">Donate</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-600 transition-transform duration-200 ${
            isExpanded ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-[800px] pb-6' : 'max-h-0'
        }`}
      >
        <div className="w-full">
          <iframe 
            src="https://donate.mycause.com.au/charity/63384?template=11&width=472"
            width="100%" 
            height="500" 
            frameBorder="0"
            title="Donation"
            className="rounded-lg"
          />
        </div>
      </div>
    </div>
  )
};

export default Donate
