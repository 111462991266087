import { useState, useMemo } from 'react'
import { ChevronDown, Search, Handshake } from 'lucide-react'

const affiliatesList = [
  { "name": "Ameinu", "url": ""},
  { "name": "The ARK Centre", "url": ""},
  { "name": "ARZA Australia Inc", "url": ""},
  { "name": "Australian Forum of Russian Speaking Jewry", "url": ""},
  { "name": "Australian Friends of Beit Halochem Inc.", "url": ""},
  { "name": "Australian Friends of Rambam", "url": ""},
  { "name": "Australian Friends of the Tel Aviv University", "url": ""},
  { "name": "Australasian Jewish Medical Federation", "url": ""},
  { "name": "Australasian Union of Jewish Students (AUJS)", "url": ""},
  { "name": "Australian Zionist Youth Council", "url": ""},
  { "name": "B'nai B'rith Victoria", "url": ""},
  { "name": "B'nei Akiva", "url": ""},
  { "name": "Beit Aharon", "url": ""},
  { "name": "Betar", "url": ""},
  { "name": "Bialik College", "url": ""},
  { "name": "Blake Street Hebrew Congregation", "url": ""},
  { "name": "Brighton Hebrew Congregation", "url": ""},
  { "name": "Caulfield Hebrew Congregation", "url": ""},
  { "name": "Central Shule", "url": ""},
  { "name": "Council of Orthodox Synagogues of Victoria", "url": ""},
  { "name": "East Melbourne Hebrew Congregation", "url": ""},
  { "name": "Elwood Hebrew Congregation", "url": ""},
  { "name": "Emunah", "url": ""},
  { "name": "Friends of Likud", "url": ""},
  { "name": "Friends of Sha'are Tzedek Hospital", "url": ""},
  { "name": "Habonim Dror", "url": ""},
  { "name": "Habonim Dror Parents and Friends", "url": ""},
  { "name": "Hadassah Australia", "url": ""},
  { "name": "Hashomer Hatzair", "url": ""},
  { "name": "Hineni", "url": ""},
  { "name": "Jewish National Fund - Keren Kayemeth L'Israel", "url": ""},
  { "name": "Kehilat Nitzan", "url": ""},
  { "name": "Kew Hebrew Congregation", "url": ""},
  { "name": "Lamm Jewish Library of Australia", "url": ""},
  { "name": "Leibler Yavneh College", "url": ""},
  { "name": "Maccabi Victoria Inc.", "url": ""},
  { "name": "Magen David Adom", "url": ""},
  { "name": "March of the Living", "url": ""},
  { "name": "Melbourne Hebrew Congregation", "url": ""},
  { "name": "Mercaz Masorti Australasia", "url": ""},
  { "name": "Meretz Australia", "url": ""},
  { "name": "Mizrachi Organisation", "url": ""},
  { "name": "Mount Scopus Memorial College", "url": ""},
  { "name": "National Council of Jewish Women (VIC)", "url": ""},
  { "name": "Netzer", "url": ""},
  { "name": "North Eastern Jewish War Memorial Centre", "url": ""},
  { "name": "Progressive Judaism of Victoria", "url": ""},
  { "name": "Sephardi Association of Victoria", "url": ""},
  { "name": "Shalom Association", "url": ""},
  { "name": "South Caulfield Hebrew Congregation", "url": ""},
  { "name": "St Kilda Hebrew Congregation", "url": ""},
  { "name": "The King David School", "url": ""},
  { "name": "Tzofim (Israeli Scouts)", "url": ""},
  { "name": "Technion AUS", "url": "https://israelrescue.org/en_AU/"},
  { "name": "United Hatzalah", "url": "https://israelrescue.org/en_AU/"},
  { "name": "United Israel Appeal - Keren Hayesod (UIA)", "url": ""},
  { "name": "United Jewish Education Board (UJEB)", "url": ""},
  { "name": "Women's International Zionist Organisation - WIZO", "url": ""},
  { "name": "ZDVO Beit Halochem", "url": ""}
]

const Affiliates = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedLetterGroup, setSelectedLetterGroup] = useState<string | null>(null)

  // Define letter groups to reduce horizontal width
  const letterGroups = [
    { label: 'A-C', regex: /^[A-C]/ },
    { label: 'D-G', regex: /^[D-G]/ },
    { label: 'H-L', regex: /^[H-L]/ },
    { label: 'M-P', regex: /^[M-P]/ },
    { label: 'Q-T', regex: /^[Q-T]/ },
    { label: 'U-Z', regex: /^[U-Z]/ }
  ]

  const filteredAffiliates = useMemo(() => {
    return affiliatesList
      .filter(org => 
        org.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!selectedLetterGroup || 
          letterGroups.find(group => group.label === selectedLetterGroup)?.regex.test(org.name) || false)
      )
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [searchTerm, selectedLetterGroup])

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm mb-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between"
      >
        <div className="flex items-center gap-3">
          <Handshake className="w-5 h-5 text-zv-blue-dark" />
          <span className="text-lg font-semibold text-gray-800">Affiliates</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-600 transition-transform duration-200 ${
            isExpanded ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-[800px] pb-6' : 'max-h-0'
        }`}
      >
        <p className="text-gray-700 mb-4">
          Zionism Victoria is the roof body of 56 affiliate organisations. We strive to represent these Zionist and Jewish organisations within the Jewish and wider communities. Check out their websites and social media pages to see what exciting things these diverse organisations have to offer.
        </p>

        {/* Search and Filter */}
        <div className="mb-4 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="w-5 h-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search affiliates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-zv-blue-light focus:border-transparent outline-none"
          />
        </div>

        {/* Letter Group Filter */}
        <div className="flex justify-center space-x-2 mb-4 flex-wrap">
          <button 
            onClick={() => setSelectedLetterGroup(null)} 
            className={`px-2 py-1 rounded m-1 ${selectedLetterGroup === null ? 'bg-zv-blue-dark text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            All
          </button>
          {letterGroups.map(group => (
            <button 
              key={group.label}
              onClick={() => setSelectedLetterGroup(group.label)}
              className={`px-2 py-1 rounded m-1 ${selectedLetterGroup === group.label ? 'bg-zv-blue-dark text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              {group.label}
            </button>
          ))}
        </div>

        {/* Affiliates List */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {filteredAffiliates.map(org => (
            <div 
              key={org.name} 
              className="bg-gray-100 px-3 py-2 rounded-md text-sm text-gray-800 hover:bg-zv-blue-light/20 transition-colors"
            > 
              <a href={org.url == "" ? undefined : org.url} target="_blank" rel="noopener noreferrer">
                {org.name}
              </a>
            </div>
          ))}
        </div>

        {filteredAffiliates.length === 0 && (
          <p className="text-center text-gray-500">No affiliates found</p>
        )}
      </div>
    </div>
  )
}

export default Affiliates
