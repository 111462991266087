import { useState } from 'react'
import { ChevronDown, Globe } from 'lucide-react'

const ConnectingCultures = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between"
      >
        <div className="flex items-center gap-3">
          <Globe className="w-6 h-6 text-zv-blue-dark" />
          <span className="text-lg font-semibold text-gray-800">Connecting Cultures</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-600 transition-transform duration-200 ${
            isExpanded ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-[1000px] pb-6' : 'max-h-0'
        }`}
      >
        <div className="space-y-6 text-gray-700">
          <p>
            Zionism Victoria's mission of connecting Victorians with Israel is bolstered by its Jakob Frenkiel Connecting Cultures program.
          </p>
          
          <p>
            It is a free service offered to all Victorian schools and communal organisations aiming to foster cross cultural awareness. By educating and informing students and their teachers, Zionism Victoria enriches their understanding of Jewish culture and history, the Jewish connection to Israel and helps cultivate the values of tolerance and respect.
          </p>
          
          <p>
            Established over twenty years ago, by Zionism Victoria, Jakob Frenkiel Connecting Cultures has provided informative, educational and entertaining discussions and lectures to thousands of Victorians each year.
          </p>
          
          <p>
            Initially launched as the Public Speakers Bureau, and then the Public Speakers Service, our service was revamped in 2016 to give it a fresh new look and more meaning. Connecting Cultures was reborn.
          </p>

          {/* Vimeo Video Embed */}
          <div className="mt-6 pb-6 w-full" style={{paddingTop: '75%', position: 'relative'}}>
            <iframe 
              src="https://player.vimeo.com/video/1035779216?h=0096d7157b&badge=0&autopause=0&player_id=0&app_id=58479&dnt=1/embed" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
              frameBorder="0" 
              style={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%'
              }}
              className="rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectingCultures
