import { useState } from 'react'
import { ChevronDown, Users } from 'lucide-react'

const Team = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const BoardMembers = [
    { title: 'President', name: 'Elyse Schachna' },
    { title: 'Vice President', name: 'Lexi Kowal' },
    { title: 'Immediate Past President', name: 'Yossi Goldfarb' },
    { title: 'Honorary treasurer', name: 'Jonathan Black' },
    { title: 'Honorary secretary', name: 'Sharene Hambur AM' },
    { title: 'Chair of Beth Weizmann Community Centre', name: 'Tony Aarons' },
    { title: 'Chair of the Lamm library', name: 'Rolene Lamm' },
    { title: 'Member', name: 'Amie Rochman' },
    { title: 'Member', name: 'David Michelson OAM' },
    { title: 'Member', name: 'David Cohen' },
    { title: 'Member', name: 'Darren Gerber' },
    { title: 'EX - OFFICIO', name: 'Jeremy Leibler' },
    { title: 'EX - OFFICIO', name: 'Ronit Chrapot' }
  ]

  const StaffMembers = [
    { title: 'Executive Director', name: 'Zeddy Lawrence' },
    { title: 'Executive Assistant', name: 'Jodi Liebenberg' },
    { title: 'Bookkeeper', name: 'Rina Retman' },
    { title: 'habayit director', name: 'Hallely Kimchi' },
    { title: 'engagement officer', name: 'Natalie Gunn' },
    { title: 'grants manager', name: 'Cynthia Pollak' }
  ]

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between"
      >
        <div className="flex items-center gap-3">
          <Users className="w-6 h-6 text-zv-blue-dark" />
          <span className="text-lg font-semibold text-gray-800">Team</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-600 transition-transform duration-200 ${
            isExpanded ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-[800px] pb-6' : 'max-h-0'
        }`}
      >
        <div className="space-y-6">
          <div>
            <h3 className="text-md font-semibold text-gray-700 mb-4">Board</h3>
            <div className="space-y-2">
              {BoardMembers.map((member, index) => (
                <div key={index} className="flex flex-col sm:flex-row sm:justify-between">
                  <span className="text-xs text-gray-500 sm:text-right sm:w-1/2 sm:pr-4">{member.title}</span>
                  <span className="font-bold text-gray-800 sm:w-1/2">{member.name}</span>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-md font-semibold text-gray-700 mb-4">Staff</h3>
            <div className="space-y-2">
              {StaffMembers.map((member, index) => (
                <div key={index} className="flex flex-col sm:flex-row sm:justify-between">
                  <span className="text-xs text-gray-500 sm:text-right sm:w-1/2 sm:pr-4">{member.title}</span>
                  <span className="font-bold text-gray-800 sm:w-1/2">{member.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
