import { useState } from 'react'
import { Check } from 'lucide-react'

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="text-center">
      <p className="text-gray-800 leading-relaxed text-lg">
        Zionism Victoria fosters a deep connection between the Victorian Jewish community and Israel, as well as facilitating a bridge between the Jewish community and the extended general community.
      </p>
      
      <div className={`mt-6 space-y-4 transition-all duration-300 ease-in-out ${isExpanded ? 'opacity-100 max-h-[500px]' : 'opacity-0 max-h-0 overflow-hidden'}`}>
        <h3 className="font-semibold text-gray-800 text-xl">Our Mission</h3>
        <ul className="space-y-4 text-left max-w-lg mx-auto">
          <li className="flex items-start gap-3">
            <Check className="w-5 h-5 text-zv-blue-dark flex-shrink-0 mt-1" />
            <span className="text-gray-800">
              To develop deeper connections between the Victorian Jewish community and Israel, in particular attempting to include a wider cross section of the community in our activities.
            </span>
          </li>
          <li className="flex items-start gap-3">
            <Check className="w-5 h-5 text-zv-blue-dark flex-shrink-0 mt-1" />
            <span className="text-gray-800">
              To actively engage with the general Victorian community with a view to expressing, explaining and promoting Israel's interests.
            </span>
          </li>
        </ul>
      </div>

      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="mt-4 text-zv-blue-dark hover:text-zv-blue-light transition-colors duration-200 font-medium border-b-2 border-transparent hover:border-zv-blue-light"
      >
        {isExpanded ? 'Show less' : 'Read more'}
      </button>
    </div>
  )
}

export default About
