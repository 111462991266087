import { ExternalLink, BookAIcon } from 'lucide-react'

const links = [
  {
    category: "Community",
    items: [
      {
        title: "Yom Hazikaron ceremony form",
        description: "If you are interested in lighting a candle or sharing the story of a fallen family member or friend.",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSc_k5rvMkBmKpyp8A4ezO8cgDRCrRykdYHr0H2GscFf8fBcmQ/viewform",
        icon: BookAIcon
      },
      {
        title: "Habyit",
        description: "Habayit is the home for Hebrew and Israeli culture in Australia since 2013",
        url: "https://www.instagram.com/habayit.aust/", 
        icon: null,
        image: "/habyit.jpg"
      },
      {
        title: "Beth Weizmann Jewish Community Centre",
        description: "We are home to more than 17 Jewish organisations.",
        url: "https://bwjcc.org.au/",
        icon: null,
        image: "/Beth-W-logo-Transparent-for-Jodi.png"
      },
      {
        title: "Lamm Jewish Library ",
        description: "A comprehensive national resource centre and community hub based in Melbourne.",
        url: "https://ljla.org.au/",
        icon: null,
        image: "/LJLA_Logo_80px.png"
      },
    ]
  },
]

const Links = () => {
  return (
    <div className="w-full space-y-8">
      {links.map((category) => (
        <div key={category.category} className="space-y-4">
          <div className="space-y-3">
            {category.items.map((link) => (
              <a
                key={link.title}
                href={link.url}
                className="block px-4 py-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    {link.icon ? (
                      <link.icon className="w-6 h-6 text-zv-blue-dark" />
                    ) : link.image ? (
                      <img 
                        src={link.image} 
                        alt={`${link.title} Logo`} 
                        className="w-10 h-10 object-cover rounded-md"
                      />
                    ) : null}
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-gray-900 font-medium">{link.title}</h3>
                    <p className="text-gray-500 text-sm">{link.description}</p>
                  </div>
                  {link.url && <ExternalLink className="w-5 h-5 text-gray-400" />}
                </div>
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Links
