import { Instagram, Facebook, Twitter, Linkedin, Youtube } from 'lucide-react'

const Footer = () => {
  const socialLinks = [
    {
      icon: Instagram,
      url: "https://www.instagram.com/zionismvictoria",
      label: "Instagram"
    },
    {
      icon: Facebook,
      url: "https://www.facebook.com/ZionismVictoria/",
      label: "Facebook"
    },
    {
      icon: Twitter,
      url: "https://x.com/zionismvic",
      label: "X (Twitter)"
    },
    {
      icon: Linkedin,
      url: "https://www.linkedin.com/company/zionism-victoria",
      label: "LinkedIn"
    },
    // {
    //   icon: threadsIcon,
    //   url: "https://www.threads.net/@zionismvictoria",
    // }
    {
      "icon": Youtube,
      "url": "https://www.youtube.com/user/ZionistCouncil",
      "label": "Youtube"
    }
  ]

  return (
    <footer className="w-full">
      {/* Social Links */}
      <div className="flex justify-center gap-6 mb-6">
        {socialLinks.map((social) => (
          <a
            key={social.label}
            href={social.url}
            aria-label={social.label}
            className="text-gray-600 hover:text-zv-blue-dark transition-colors duration-200 p-2"
          >
            <social.icon className="w-5 h-5" />
          </a>
        ))}
      </div>

      {/* Additional Info */}
      <div className="text-center text-sm text-gray-600 space-y-3">
        <div className="py-1">
          <a
            href="https://www.acnc.gov.au/charity/charities/f7b67ad3-39af-e811-a963-000d3ad24077/profile"
            className="hover:text-zv-blue-dark transition-colors duration-200 py-1 px-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            State Zionist Council Of Vic is registered with the Australian Charities and Not-for-profits Commission (ACNC).
            <br /> ABN 93880343953 © 2024.
          </a>
        </div>
        <div className="py-1">
          <a
            href="https://ashley.rodan.co"
            className="hover:text-zv-blue-dark transition-colors duration-200 py-1 px-2"
            target="_blank"
            rel="noopener noreferrer"
            >
            <span>
              WebApps by Ashley
          </span>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
