import { executeQuery } from '@datocms/cda-client';

export interface Event {
  id: string;
  title: string;
  biline?: string;
  banner?: {
    url: string;
  };
  url: string;
  _status: string;
  _firstPublishedAt: string;
}

export interface DatoQueryResult {
  allEvents: Event[];
  _allLinksMeta: {
    count: number;
  };
}

export const fetchDatoData = async (): Promise<DatoQueryResult | null> => {
  const query = `{
    allEvents {
      id
      title
      biline
      url
      banner {
        url
      }
      _status
      _firstPublishedAt
    }
    _allLinksMeta {
      count
    }
  }`;

  try {
    // Explicitly log all env variables for troubleshooting in production
    console.log('Environment variables available:', 
      Object.keys(import.meta.env)
        .filter(key => key.startsWith('VITE_'))
        .map(key => `${key}: ${key === 'VITE_DATOCMS_READONLY_TOKEN' ? '[PRESENT]' : '[VALUE]'}`)
    );
    
    const token = import.meta.env.VITE_DATOCMS_READONLY_TOKEN;
    
    if (!token) {
      console.error('DatoCMS token not found in environment variables. Skipping data fetch.');
      return null;
    }

    console.log(`Attempting to fetch DatoCMS data with token: ${token.slice(0, 4)}...${token.slice(-4)}`);
    
    const result = await executeQuery(query, {
      token,
    });

    console.log('DatoCMS data fetch successful:', !!result);
    return result as DatoQueryResult;
  } catch (error) {
    console.error('Error fetching DatoCMS data:', error);
    return null;
  }
};
