const Logo = () => {
  return (
    <div className="w-full flex justify-center">
      <img
        src="/logo_new.png"
        alt="Zionism Victoria logo"
        className="w-full max-w-[650px] h-auto"
      />
    </div>
  )
}

export default Logo
