import { useState, FormEvent, useEffect } from 'react'
import { Phone, MapPin, ChevronDown, Send } from 'lucide-react'
import emailjs from '@emailjs/browser'

interface ContactMessage {
  name: string
  email: string
  message: string
  timestamp: number
}

const Contact = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null)
  const [submitMessage, setSubmitMessage] = useState<string>('')

  useEffect(() => {
    // Initialize EmailJS with the public key
    emailjs.init('m1OCEHVeJiucD6aOH')
  }, [])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    setSubmitMessage('')
    
    try {
      // Send email via EmailJS
      const response = await emailjs.send("service_1hwahhl", "template_x9fhlek", {
        name: formData.name,
        message: formData.message,
        email: formData.email
      })

      // Store message in localStorage
      const message: ContactMessage = {
        ...formData,
        timestamp: Date.now()
      }
      
      const existingMessages = JSON.parse(localStorage.getItem('contactMessages') || '[]')
      localStorage.setItem('contactMessages', JSON.stringify([...existingMessages, message]))

      // Set success status and message
      setSubmitStatus('success')
      setSubmitMessage(`Message sent successfully! (Status: ${response.status}, Text: ${response.text})`)

      // Reset form
      setFormData({ name: '', email: '', message: '' })
    } catch (error) {
      // Set error status and message
      setSubmitStatus('error')
      setSubmitMessage(`Failed to send message: ${error instanceof Error ? error.message : 'Unknown error'}`)
      
      console.error('EmailJS Error:', error)
    } finally {
      setIsSubmitting(false)
      // Clear status after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null)
        setSubmitMessage('')
      }, 5000)
    }
  }

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between"
      >
        <div className="flex items-center gap-3"> 
          <Phone className="w-6 h-6 text-zv-blue-dark" />
          <span className="text-lg font-semibold text-gray-800">Contact Us</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-600 transition-transform duration-200 ${
            isExpanded ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      <div
        className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-[800px] pb-6' : 'max-h-0'
        }`}
      >
        <div className="space-y-6">
          {/* Contact Info */}
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <Phone className="w-5 h-5 text-zv-blue-dark flex-shrink-0" />
              <a
                href="tel:0392725544"
                className="text-gray-700 hover:text-zv-blue-dark transition-colors"
              >
                03 9272 5544
              </a>
            </div>

            <div className="flex items-start gap-3">
              <MapPin className="w-5 h-5 text-zv-blue-dark flex-shrink-0 mt-1" />
              <address className="text-gray-700 not-italic">
                306 Hawthorn Rd<br />
                Caulfield South VIC 3162
              </address>
            </div>
          </div>

          {/* Contact Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-zv-blue-light focus:border-transparent outline-none"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-zv-blue-light focus:border-transparent outline-none"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                Message
              </label>
              <textarea
                id="message"
                required
                rows={4}
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-zv-blue-light focus:border-transparent outline-none resize-none"
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center px-4 py-2 bg-zv-blue-dark text-white rounded-md hover:bg-zv-blue-light transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed gap-2"
              >
                <Send className="w-4 h-4" />
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>

              {submitMessage && (
                <p className={`text-sm ${submitStatus === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                  {submitMessage}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
