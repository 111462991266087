import { Event } from '../utils/datocms';
import { ExternalLink } from 'lucide-react';
import React from 'react';

interface EventBannerProps {
  event: Event;
}

const EventBanner: React.FC<EventBannerProps> = ({ event }) => {
  return (
    <a 
      href={event.url}
      target="_blank" 
      rel="noopener noreferrer"
      className="block px-4 py-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
    >
      <div className="flex flex-col space-y-3">
        {event.banner && event.banner.url && (
          <img 
            src={event.banner.url}
            alt={event.title || "Event Banner"} 
            className="w-full h-auto object-cover rounded-md"
          />
        )}
        <div>
          <div className="flex items-center justify-between">
            <h2 className="text-gray-900 font-medium">
              {event.title || "500 Days"}
            </h2>
            <ExternalLink className="w-5 h-5 text-gray-400" />
          </div>
          <p className="text-gray-500 text-sm mb-2">
            {event.biline || "Mon, 17 Feb, 6:30pm - 7:30pm"}
          </p>
          <div className="flex justify-center">
            <span className="inline-block bg-zv-blue-dark text-white px-4 py-1 rounded-full text-sm">
              Get Tickets
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default EventBanner;
